// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-curso-amplifica-tu-confianza-js": () => import("./../../../src/pages/curso-amplifica-tu-confianza.js" /* webpackChunkName: "component---src-pages-curso-amplifica-tu-confianza-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ivone-templates-js": () => import("./../../../src/pages/ivone-templates.js" /* webpackChunkName: "component---src-pages-ivone-templates-js" */),
  "component---src-pages-kifah-templates-js": () => import("./../../../src/pages/kifah-templates.js" /* webpackChunkName: "component---src-pages-kifah-templates-js" */),
  "component---src-pages-old-index-js": () => import("./../../../src/pages/old-index.js" /* webpackChunkName: "component---src-pages-old-index-js" */),
  "component---src-pages-pasos-nuevo-suscriptor-js": () => import("./../../../src/pages/pasos-nuevo-suscriptor.js" /* webpackChunkName: "component---src-pages-pasos-nuevo-suscriptor-js" */),
  "component---src-pages-pasos-resultados-test-js": () => import("./../../../src/pages/pasos-resultados-test.js" /* webpackChunkName: "component---src-pages-pasos-resultados-test-js" */),
  "component---src-pages-power-coaching-js": () => import("./../../../src/pages/power-coaching.js" /* webpackChunkName: "component---src-pages-power-coaching-js" */),
  "component---src-pages-programa-the-confidence-revolution-js": () => import("./../../../src/pages/programa-the-confidence-revolution.js" /* webpackChunkName: "component---src-pages-programa-the-confidence-revolution-js" */),
  "component---src-pages-proximamente-js": () => import("./../../../src/pages/proximamente.js" /* webpackChunkName: "component---src-pages-proximamente-js" */),
  "component---src-pages-sobre-mi-2021-js": () => import("./../../../src/pages/sobre-mi 2021.js" /* webpackChunkName: "component---src-pages-sobre-mi-2021-js" */),
  "component---src-pages-sobre-mi-2022-js": () => import("./../../../src/pages/sobre-mi 2022.js" /* webpackChunkName: "component---src-pages-sobre-mi-2022-js" */),
  "component---src-pages-sobre-mi-js": () => import("./../../../src/pages/sobre-mi.js" /* webpackChunkName: "component---src-pages-sobre-mi-js" */),
  "component---src-pages-survey-js": () => import("./../../../src/pages/survey.js" /* webpackChunkName: "component---src-pages-survey-js" */),
  "component---src-pages-test-reconoces-tu-valor-y-confias-realmente-en-ti-misma-js": () => import("./../../../src/pages/test-reconoces-tu-valor-y-confias-realmente-en-ti-misma.js" /* webpackChunkName: "component---src-pages-test-reconoces-tu-valor-y-confias-realmente-en-ti-misma-js" */)
}

